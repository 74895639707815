import { StyleSheet } from "aphrodite";
import {
  fontSize,
  fontWeight,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  accentColor,
  borderColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";

export interface IStyle {
  block: any;
  label: any;
  listBlock: any;
  list: any;
  link: any;
}

export const SimpleTextLinkListStyle: IStyle = StyleSheet.create({
  block: {
    padding: `${space.atom} 0`,
    [moreThanBreakpoint("tablet")]: {
      display: "flex",
    },
  },
  label: {
    fontSize: fontSize.body,
    fontWeight: fontWeight.bold,
    paddingBottom: space.atom,
    [moreThanBreakpoint("tablet")]: {
      width: "10rem",
    },
  },
  listBlock: {
    flex: 1,
    padding: 0,
  },
  list: {
    display: "inline-block",
    fontSize: fontSize.body,
    lineHeight: lineHeight.body1,
    color: textColor.primaryDarkColor,
    marginBottom: space.atom0_5x,
    ":after": {
      content: '"/"',
      color: borderColor.secondaryLightColor,
      padding: `0 ${space.atom1_5x}`,
      opacity: 0.5,
    },
  },
  link: {
    color: textColor.secondaryDarkColor,
    transition: "color 0.4s ease",
    ":hover": {
      color: accentColor.primaryColor,
    },
  },
});
