import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import {
  fontSize,
  lineHeight,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  textColor,
} from "main/javascripts/styles/base/colorStyle";

export interface IStyle {
  block: any;
  inner: any;
  title: any;
  link: any;
  linkListBlock: any;
  linkList: any;
}

export const RecommendedInfoStyle: IStyle = StyleSheet.create({
  block: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  inner: {
    padding: space.atom,
    boxSizing: "border-box",
  },
  title: {
    fontSize: fontSize.mediumHeading,
    lineHeight: lineHeight.mediumHeadline2,
    letterSpacing: "0.05em",
    color: textColor.secondaryDarkColor,
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    textDecoration: "none",
    color: accentColor.primaryColor,
    fontSize: fontSize.mediumHeading,
    lineHeight: lineHeight.mediumHeadline2,
    letterSpacing: "0.05em",
    transition: "color 0.4s ease",
    padding: `${space.atom} 0`,
    maxWidth: "15rem",
    margin: "0 0 0 auto",
    ":hover": {
      color: accentColor.primaryDarkColor,
    },
  },
  linkListBlock: {
    padding: `${space.atom2x} 0`,
  },
  linkList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    textDecoration: "none",
    color: textColor.primaryDarkColor,
    fontSize: fontSize.caption,
    lineHeight: lineHeight.caption2,
    letterSpacing: "0.05em",
    transition: "color 0.4s ease",
    maxWidth: "15rem",
    margin: "0 0 0 auto",
    ":hover": {
      color: accentColor.primaryColor,
    },
  },
});
