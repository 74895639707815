import React, { useRef, useState } from "react";
import { css } from "@emotion/react";
import { useFormContext, useWatch } from "react-hook-form";
import { format } from "date-fns";
import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { CheckinInput } from "main/javascripts/components/hotel/form/CheckinInput";
import { CheckoutInput } from "main/javascripts/components/hotel/form/CheckoutInput";
import { FullScreenModalDialog } from "main/javascripts/components/molecules/Modal/FullScreenModalDialog";
import { ScrollableDatePicker } from "main/javascripts/components/molecules/DatePicker";
import { MAX_SEARCH_HOTEL_DAYS } from "main/javascripts/constants/HotelConstants";
import { isPhone } from "main/javascripts/styles/base/responsiveStyle";
import { HorizontalDatePicker } from "main/javascripts/components/molecules/DatePicker/HorizontalDatePicker";
import { DateTypes } from "main/javascripts/constants/DateTypes";
import { parseDate } from "main/javascripts/utils/DateUtil";
import { space } from "../../styles/base/spaceStyle";
import { useSelector } from "react-redux";
import { IColorStyle } from "../form/Label";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { event } from "../../utils/googleTagManagerUtil";
import { GoogleTagManagerEventTypes } from "../../constants/GoogleTagManagerEventTypes";

export interface IProps {
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  disableStopBodyScrolling?: boolean;
}

const HotelDatePickerBlockComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    labelColorStyleKey = "default",
    borderColorStyleKey,
    disableStopBodyScrolling,
  } = props;
  const { t } = useTranslation(["component"]);

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const { setValue, control, trigger } = useFormContext();
  const [checkinDateValue, checkoutDateValue] = useWatch({
    control,
    name: ["checkin_date", "checkout_date"],
  });

  const [displayedPicker, setDisplayedPicker] = useState(false);
  const [pickerDateType, setPickerDateType] = useState(null);

  const inputCheckInRef = useRef<any>();
  const inputCheckOutRef = useRef<any>();

  // public shouldComponentUpdate(nextProps: any, nextState: any): boolean {
  //   return !isEqual(props, nextProps) || !isEqual(state, nextState);
  // }

  const onClickCheckinDate: () => void = () => {
    setDisplayedPicker(true);
    setPickerDateType(DateTypes.fromDate);
  };

  const onClickCheckoutDate: () => void = () => {
    setDisplayedPicker(true);
    setPickerDateType(DateTypes.toDate);
  };

  const sendDateEvent = (
    checkinDateString?: string,
    checkoutDateString?: string
  ) => {
    if (checkinDateString && checkoutDateString) {
      event(GoogleTagManagerEventTypes.date, {
        start_date: checkinDateString,
        end_date: checkoutDateString,
      });
    }
  };

  const onComplete: (startDate: Date | null, endDate: Date | null) => void = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    let checkinDateString = "";
    let checkoutDateString = "";
    if (startDate) {
      checkinDateString = format(startDate, "yyyy/LL/dd");
      setValue("checkin_date", checkinDateString);
      void trigger(["checkin_date"]);
    }
    if (endDate) {
      checkoutDateString = format(endDate, "yyyy/LL/dd");
      setValue("checkout_date", checkoutDateString);
      void trigger(["checkout_date"]);
    }
    sendDateEvent(checkinDateString, checkoutDateString);
    hidePicker();
  };

  const onUpdate: (
    startDate: Date | null,
    endDate: Date | null,
    isEndStep: boolean
  ) => void = (
    startDate: Date | null,
    endDate: Date | null,
    isEndStep: boolean
  ) => {
    let checkinDateString = "";
    let checkoutDateString = "";
    if (startDate) {
      checkinDateString = format(startDate, "yyyy/LL/dd");
      setValue("checkin_date", checkinDateString);
      void trigger(["checkin_date"]);
    }
    if (endDate) {
      checkoutDateString = format(endDate, "yyyy/LL/dd");
      setValue("checkout_date", checkoutDateString);
      void trigger(["checkout_date"]);
    }
    if (!isEndStep) {
      setPickerDateType(DateTypes.toDate);
    }
    sendDateEvent(checkinDateString, checkoutDateString);
  };

  const hidePicker: () => void = () => {
    setDisplayedPicker(false);
    setPickerDateType(null);
  };

  // 再レンダリングの負荷が高い場合はメモ化検討する
  const fromDate: Date | null = checkinDateValue
    ? parseDate(checkinDateValue)
    : null;
  const toDate: Date | null = checkoutDateValue
    ? parseDate(checkoutDateValue)
    : null;

  return (
    <div css={blockStyle}>
      <div css={inputBlockStyle}>
        <CheckinInput
          namespace="hotel"
          isActive={pickerDateType === DateTypes.fromDate}
          onClick={onClickCheckinDate}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          inputBlockRef={inputCheckInRef}
        />
        <CheckoutInput
          namespace="hotel"
          isActive={pickerDateType === DateTypes.toDate}
          onClick={onClickCheckoutDate}
          labelColorStyleKey={labelColorStyleKey}
          borderColorStyleKey={borderColorStyleKey}
          inputBlockRef={inputCheckOutRef}
        />
      </div>
      {isPhone(breakpoint) ? (
        <FullScreenModalDialog
          displayed={displayedPicker}
          closeHandler={hidePicker}
          colorMode="light"
          disableStopBodyScrolling={disableStopBodyScrolling}
        >
          <ScrollableDatePicker
            fromDate={fromDate}
            toDate={toDate}
            onComplete={onComplete}
            numberOfMonths={12}
            maxDays={MAX_SEARCH_HOTEL_DAYS}
            outOfRangeMessage={t("search.calendarMaxDaysMessage")}
            startDateLabel={t("label:common.checkinDate")}
            endDateLabel={t("label:common.checkoutDate")}
            dateType={pickerDateType}
          />
        </FullScreenModalDialog>
      ) : (
        <HorizontalDatePicker
          fromDate={fromDate}
          toDate={toDate}
          onUpdate={onUpdate}
          hidePicker={hidePicker}
          numberOfMonths={12}
          maxDays={MAX_SEARCH_HOTEL_DAYS}
          outOfRangeMessage={t("search.calendarMaxDaysMessage")}
          startDateLabel={t("label:common.checkinDate")}
          endDateLabel={t("label:common.checkoutDate")}
          displayedPicker={displayedPicker}
          dateType={pickerDateType}
          fromInputRef={inputCheckInRef.current}
          toInputRef={inputCheckOutRef.current}
        />
      )}
    </div>
  );
};

const areEqual: any = (prevProps: any, nextProps: any): any => {
  return isEqual(prevProps, nextProps);
};
export const HotelDatePickerBlock: any = React.memo(
  HotelDatePickerBlockComponent,
  areEqual
);

const blockStyle = css`
  position: relative;
  flex: 1;
`;
const inputBlockStyle = css`
  display: flex;
  padding: ${space.atom} 0 0;
`;
