import { StyleSheet } from "aphrodite";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";

export interface IStyle {
  block: any;
  blockWide: any;
  blockFullWidth: any;
  blockMultiCol: any;
}

export const BasicBlockStyle: IStyle = StyleSheet.create({
  block: {
    padding: `${space.block2x} ${space.block}`,
    maxWidth: maxWidth.page1col,
    margin: "0 auto",
    boxSizing: "border-box",
  },
  blockWide: {
    padding: `${space.block2x} ${space.block}`,
    maxWidth: maxWidth.page1colWide,
    margin: "0 auto",
    boxSizing: "border-box",
  },
  blockFullWidth: {
    padding: `${space.block2x} 0`,
    maxWidth: "100%",
    boxSizing: "border-box",
  },
  blockMultiCol: {
    [moreThanBreakpoint("desktop")]: {
      padding: `${space.block2x} ${space.block}`,
      margin: "0 auto",
      maxWidth: maxWidth.page2col,
      boxSizing: "border-box",
    },
  },
});
