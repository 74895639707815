import * as React from "react";
import { css } from "aphrodite";
import { withTranslation, WithTranslation } from "react-i18next";
import { RecommendedInfoStyle } from "./RecommendedInfoStyle";
import { BasicBlock } from "main/javascripts/components/atoms/Block/BasicBlock";
import { SimpleTextLinkList } from "main/javascripts/components/molecules/Link/SimpleTextLinkList";
import { Icon } from "main/javascripts/components/atoms/Icon";
import { PATH_SCOPE } from "main/javascripts/constants/Constants";

export interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  styles?: any;
  t?: any;
}

const cityCodeData: any[] = [
  "PAR",
  "LON",
  "ROM",
  "BCN",
  "SFO",
  "TPE",
  "SEL",
  "SIN",
  "HKG",
  "DPS",
  "BKK",
];

export const RecommendedInfoComponent: React.FC<IProps & WithTranslation> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles = {}, t } = props;
  const { block } = styles;
  const flightData: any[] = cityCodeData.map((code: string) => ({
    text: t(`constant:cityCode.${code}`),
    url: `${PATH_SCOPE}/lp/flights/dst_regions/${code}`,
  }));

  const hotelData: any[] = cityCodeData.map((code: string) => ({
    text: t(`constant:cityCode.${code}`),
    url: `${PATH_SCOPE}/lp/hotels/dst_regions/${code}`,
  }));
  return (
    <BasicBlock
      styles={[RecommendedInfoStyle.block, block]}
      styleKey="blockWide"
    >
      <div className={css(RecommendedInfoStyle.inner)}>
        <h4 className={css(RecommendedInfoStyle.title)}>
          {t("search.popularDestinations")}
        </h4>
        <div className={css(RecommendedInfoStyle.linkListBlock)}>
          <SimpleTextLinkList label={t("search.flight")} data={flightData} />
        </div>
        <div className={css(RecommendedInfoStyle.linkListBlock)}>
          <SimpleTextLinkList label={t("search.hotel")} data={hotelData} />
        </div>
        <a
          className={css(RecommendedInfoStyle.link)}
          href={`${PATH_SCOPE}/posts`}
        >
          <span>記事一覧</span> <Icon styleKey="arrowThinR" />
        </a>
      </div>
    </BasicBlock>
  );
};
export const RecommendedInfo: any = withTranslation(["component"])(
  RecommendedInfoComponent
);
