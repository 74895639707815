import * as React from "react";
import { css } from "aphrodite";
import { SimpleTextLinkListStyle } from "./SimpleTextLinkListStyle";
import { A } from "main/javascripts/components/atoms/A";

export interface IProps {
  label?: string;
  data: { text: string; url: string }[];
}

export const SimpleTextLinkList: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const { label, data } = props;
  return (
    <div className={css(SimpleTextLinkListStyle.block)}>
      {label && (
        <div className={css(SimpleTextLinkListStyle.label)}>{label}</div>
      )}
      <ul className={css(SimpleTextLinkListStyle.listBlock)}>
        {data.map((link: any, index: number) => {
          return (
            <li key={index} className={css(SimpleTextLinkListStyle.list)}>
              <A href={link.url} styles={SimpleTextLinkListStyle.link}>
                {link.text}
              </A>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
