import * as React from "react";
import { css } from "aphrodite";
import { BasicBlockStyle, IStyle } from "./BasicBlockStyle";

interface IProps extends React.AllHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  styles?: any;
  styleKey?: keyof IStyle;
}

const BasicBlockComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { styles, children, styleKey = "block" } = props;
  return (
    <div className={css(BasicBlockStyle[styleKey], styles)}>{children}</div>
  );
};
export const BasicBlock: any = React.memo(BasicBlockComponent);
