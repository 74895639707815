import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { stringify } from "qs";
import { useRouter } from "next/router";
import { css } from "@emotion/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { decamelize } from "humps";
import { FlightTravelerBlock } from "main/javascripts/components/flight/FlightTravelerBlock";
import { CabinClassBlock } from "main/javascripts/components/flight/form/CabinClassBlock";
import { FlightOriginDestinationDate } from "main/javascripts/components/flight/FlightOriginDestinationDate";
import { FlightTypeBlock } from "main/javascripts/components/flight/form/FlightTypeBlock";
import { ButtonWithLoader } from "main/javascripts/components/button/ButtonWithLoader";
import { searchCategories } from "main/javascripts/constants/SearchCategories";
import { useAppDispatch } from "main/javascripts/store";
import {
  convertSearchFlightFormValuesToParams,
  postSearchFlightCache,
} from "../../flight/searchFlightSlice";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { moreThanBreakpoint } from "main/javascripts/styles/base/responsiveStyle";
import { maxWidth } from "main/javascripts/styles/base/maxWidthStyle";
import { debouncedFetchFlightDestinationSuggestions } from "main/javascripts/features/flight/flightSuggestionSlice";
import { flightAvailabilitiesEvent } from "main/javascripts/utils/googleTagManagerUtil";
import {
  FormNamespaces,
  yupDefaultMessages,
} from "main/javascripts/constants/FormConstants";
import { FlightTypes } from "main/javascripts/constants/FlightTypes";
import { formatAndConvertServerErrorToForm } from "main/javascripts/utils/FormUtil";
import { FormErrorsBlock } from "main/javascripts/components/form/FormErrorsBlock";

const defaultValues = {
  departure_date: null, // undefinedではダメ
  return_date: null, // undefinedではダメ
  traveler: {
    number_of_adult: 2,
    number_of_children: 0,
  },
  access_type: "direct_web",
  flight_type: decamelize("roundTrip"),
  cabin_class: "Y",
  itineraries: [{ departure_date: null }, { departure_date: null }],
};

type SearchFlightFormData = {
  form?: any; // エラー用
  flight_type: string;
  origin: string;
  origin_id: number;
  origin_type: string;
  destination: string;
  destination_id: number;
  destination_type: string;
  departure_date: string;
  return_date: string;
  itineraries: any[];
  traveler: any;
};

yup.setLocale(yupDefaultMessages);
const schema = yup.object().shape({
  origin: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.string().nullable().required(),
    }),
  origin_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null))
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.number().nullable().required(),
    }),
  destination: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.string().nullable().required(),
    }),
  destination_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val ? Number(val) : null))
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.number().nullable().required(),
    }),
  departure_date: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val !== FlightTypes.multiCity,
      then: yup.string().nullable().required(),
    }),
  return_date: yup
    .string()
    .nullable()
    .when("flight_type", {
      is: (val) => val === FlightTypes.roundTrip,
      then: yup.string().nullable().required(),
    }),
  itineraries: yup
    .array()
    .nullable()
    .when("flight_type", {
      is: (val) => val === FlightTypes.multiCity,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            origin: yup.string().required(),
            origin_id: yup.number().required(),
            destination: yup.string().required(),
            destination_id: yup.number().required(),
            departure_date: yup.string().nullable().required(),
          })
        ),
    }),
  traveler: yup.object().shape({
    number_of_children: yup
      .number()
      .nullable()
      .transform((_, val) => (val || val === 0 ? Number(val) : null))
      .required(),
    ages_of_children: yup.array().when("number_of_children", {
      is: (val) => Number(val) > 0,
      then: yup.array().of(
        yup.object().shape({
          age: yup
            .number()
            .nullable()
            .transform((_, val) => (val || val === 0 ? Number(val) : null))
            .required()
            .test(
              "infantsNumber",
              "infantsNumber",
              function (value, context: any) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_, parent2] = context.from;
                const infantsNumber: number = (
                  parent2?.value?.ages_of_children || []
                ).filter((age: any): boolean => {
                  // 空文字を無視したいためparseIntを使用
                  return parseInt(age.age) <= 1;
                }).length;
                const adultsNumber = Number(
                  parent2?.value?.number_of_adult || 1
                );
                return infantsNumber <= adultsNumber;
              }
            ),
        })
      ),
    }),
  }),
});

export const SearchFlight = (): React.ReactElement => {
  const router = useRouter();
  const appDispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<SearchFlightFormData>({
    mode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    clearErrors("form");
    handleSubmit(onSubmit)(e);
  };

  const onSubmit: (values: any) => void = async (values: any) => {
    setIsSubmitting(true);
    const params: any = convertSearchFlightFormValuesToParams(values);
    return appDispatch(postSearchFlightCache({ params }))
      .then(unwrapResult)
      .then((result) => {
        const cacheId = result.data.cacheId;
        flightAvailabilitiesEvent(cacheId, params);
        router.push(
          `/flights?${stringify(params, {
            arrayFormat: "indices",
          })}&cache_id=${cacheId}`
        );
      })
      .catch((errorResponse) => {
        setIsSubmitting(false);
        formatAndConvertServerErrorToForm(errorResponse, setError);
      });
  };

  const fetchFlightDestinationSuggestionsAction = (
    data: any,
    type: string,
    index?: number
  ) => {
    const { text, ...rest } = data;
    appDispatch(
      debouncedFetchFlightDestinationSuggestions({
        params: { text: text, ...rest },
        type,
        index,
      })
    );
  };

  return (
    <div css={blockStyle}>
      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          <div css={flightTypeBlockStyle}>
            <FlightTypeBlock
              labelStyleKey="labelDark"
              borderColorStyleKey="secondary"
            />
          </div>
          <FlightOriginDestinationDate
            destinationSuggestOptions={{}}
            fetchFlightOriginDestinationSuggestions={
              fetchFlightDestinationSuggestionsAction
            }
            useDatePicker={true}
            searchType={searchCategories.flight}
            labelColorStyleKey="default"
            borderColorStyleKey="secondary"
          />
          <div css={flightClassBlockStyle}>
            <CabinClassBlock
              labelColorStyleKey="default"
              borderColorStyleKey="secondary"
            />
          </div>
          <FlightTravelerBlock
            labelColorStyleKey="default"
            borderColorStyleKey="secondary"
          />
          {errors && (
            <div>
              <FormErrorsBlock
                namespace={FormNamespaces.flight}
                errors={errors}
                hideValidationErrors={true}
              />
            </div>
          )}
          <div css={submitBlockStyle}>
            <ButtonWithLoader
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              検 索
            </ButtonWithLoader>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const submitBlockStyle = css`
  padding: ${space.atom} 0 ${space.atom2x};

  ${moreThanBreakpoint("desktop")} {
    padding-top: ${space.atom2x};
    width: 50%;
    margin-left: auto;
    margin-right: 0;
  }
`;
const blockStyle = css`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 ${space.atom};

  ${moreThanBreakpoint("tablet")} {
    padding: 0 ${space.atom};
    margin: ${space.atom} auto ${space.atom2x};
  }

  ${moreThanBreakpoint("desktop")} {
    box-sizing: border-box;
    max-width: ${maxWidth.page1colWide};
  }
`;
const flightTypeBlockStyle = css`
  padding-top: ${space.atom2x};
`;
const flightClassBlockStyle = css`
  width: 50%;
  min-width: 200px;
`;
