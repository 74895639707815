import * as React from "react";
import { searchCategories } from "main/javascripts/constants/SearchCategories";
import { useAppSelector } from "main/javascripts/store";
import { searchCategorySelector } from "../searchPageSlice";
import { SearchHotel } from "main/javascripts/features/search/components/SearchHotel";
import { SearchFlight } from "main/javascripts/features/search/components/SearchFlight";
import { SearchDynamicPackage } from "main/javascripts/features/search/components/SearchDynamicPackage";

export const Search: React.FC = (): React.ReactElement => {
  const searchCategory = useAppSelector(searchCategorySelector);

  switch (searchCategory) {
    case searchCategories.hotel:
      return <SearchHotel />;
    case searchCategories.flight:
      return <SearchFlight />;
    case searchCategories.dynamicPackage:
      return <SearchDynamicPackage />;
    default:
      return <SearchHotel />;
  }
};
