import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { get, useFormContext, useWatch } from "react-hook-form";
import { InputAutosuggestBlock } from "main/javascripts/components/form/InputAutosuggestBlock";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { useAppSelector } from "main/javascripts/store";
import {
  hotelDestinationInputDataSelector,
  hotelDestinationSuggestionsSelector,
  loadingSelector,
  setHotelDestinationInputData,
} from "main/javascripts/features/hotel/hotelSuggestionSlice";
import { IColorStyle } from "main/javascripts/components/form/Label";
import { InputHidden } from "../form/InputHidden";
import { useAppDispatch } from "main/javascripts/store";
import { useSelector } from "react-redux";
import { isPhone } from "main/javascripts/styles/base/responsiveStyle";
import { SuggestionPicker } from "../suggestionPicker/SuggestionPicker";
import { FullScreenModalDialog } from "../molecules/Modal/FullScreenModalDialog";
import { SuggestionFieldButton } from "../suggestionPicker/SuggestionFieldButton";
import { fontSize, letterSpacing } from "../../styles/base/typographyStyle";
import { IBorderColorStyle } from "main/javascripts/styles/base/formStyle";
import { Suggestion } from "../../types/suggestion";
import { FormNamespaces } from "../../constants/FormConstants";
import { event } from "../../utils/googleTagManagerUtil";
import { GoogleTagManagerEventTypes } from "../../constants/GoogleTagManagerEventTypes";
import { defaultDestinationsSelector } from "../../slices/accountParamSlice";

export interface IProps {
  fetchHotelDestinationSuggestions(data: any): void;
  defaultDestinations?: Suggestion[];
  suggestOptions?: any;
  labelColorStyleKey?: keyof IColorStyle;
  borderColorStyleKey?: keyof IBorderColorStyle;
  disableStopBodyScrolling?: boolean;
}

export const HotelDestinationBlock: React.FC<IProps> = (
  props: IProps
): React.ReactElement => {
  const {
    fetchHotelDestinationSuggestions,
    defaultDestinations,
    suggestOptions,
    labelColorStyleKey,
    borderColorStyleKey,
    disableStopBodyScrolling,
  } = props;
  const { t } = useTranslation(["label"]);
  const appDispatch = useAppDispatch();

  const accountDefaultDestinations = useAppSelector(
    defaultDestinationsSelector
  );
  const destinations = useAppSelector(hotelDestinationSuggestionsSelector);
  const isLoading = useAppSelector(loadingSelector);
  const currentInputData = useAppSelector(hotelDestinationInputDataSelector);
  const currentInputText = currentInputData?.text;
  const currentInputLongName = currentInputData?.longName;

  // TODO: 旧reducerをそのまま使用
  const breakpoint: any = useSelector<any>(
    (state) => state.breakpointReducer.breakpoint
  );

  const {
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useFormContext();
  const fieldName = "destination";
  const idFieldName = "destination_id";
  const typeFieldName = "destination_type";
  const longitudeFieldName = "longitude";
  const latitudeFieldName = "latitude";
  const value =
    useWatch({
      control,
      name: fieldName,
    }) || "";

  const [displayedPicker, setDisplayedPicker] = useState(false);

  const inputTimeoutRef = useRef(null);
  const valueRef = useRef(value);
  valueRef.current = value;
  const currentInputLongNameRef = useRef(currentInputLongName);
  currentInputLongNameRef.current = currentInputLongName;

  const currentValue = isPhone(breakpoint) ? value : value;
  const validDefaultDestinations = (
    ...values: (any[] | undefined)[]
  ): any[] | [] =>
    values.find((value) => value !== undefined && value.length > 0) ?? [];
  const suggestions = currentValue
    ? destinations
    : validDefaultDestinations(defaultDestinations, accountDefaultDestinations);

  useEffect(() => {
    return () => {
      clearTimeout(inputTimeoutRef.current);
    };
  }, []);

  const hidePicker: () => void = () => {
    setDisplayedPicker(false);
  };

  const onPickerButtonClick: () => void = () => {
    setDisplayedPicker(true);
  };

  const onSelected = (suggestion) => {
    clearTimeout(inputTimeoutRef.current);
    const inputText = currentValue;
    setValue(fieldName, suggestion.longName);
    setValue(idFieldName, suggestion.id);
    setValue(typeFieldName, suggestion.destinationType);
    setValue(longitudeFieldName, suggestion.centerCoordinates[0]);
    setValue(latitudeFieldName, suggestion.centerCoordinates[1]);
    appDispatch(
      setHotelDestinationInputData({
        text: inputText,
        longName: suggestion.longName,
      })
    );
    void trigger([fieldName, idFieldName]);

    event(GoogleTagManagerEventTypes.origin, {
      destination_id: suggestion.id,
      destination_type: suggestion.destinationType,
      destination: suggestion.longName,
    });

    if (displayedPicker) {
      hidePicker();
    }
  };

  const onClearInput: () => void = () => {
    clearTimeout(inputTimeoutRef.current);
    setValue(idFieldName, null);
    setValue(fieldName, "");
    setValue(typeFieldName, "");
    setValue(longitudeFieldName, null);
    setValue(latitudeFieldName, null);
    appDispatch(
      setHotelDestinationInputData({
        text: "",
        longName: "",
      })
    );
  };

  // reactではinputのvalueが外から変更されてもonChangeが呼ばれないため
  // 変更後のvalueをreact-autosuggestが使用できない
  // そのためreact-autosuggestのfocus時のrequestを
  // disableOnFocusRequestで抑止し、独自でリクエストする
  const onFocus = () => {
    clearTimeout(inputTimeoutRef.current);
    setValue(fieldName, currentInputText);
    if (currentInputText) {
      fetchHotelDestinationSuggestions({ text: currentInputText });
    }
  };

  const onBlur = () => {
    // onSelectedより先に呼ばれるため遅延させる
    inputTimeoutRef.current = setTimeout(() => {
      if (
        // valueRef.current &&
        currentInputLongNameRef.current &&
        valueRef.current !== currentInputLongNameRef.current
      ) {
        setValue(fieldName, currentInputLongNameRef.current);
        void trigger(fieldName);
      }
    }, 400);
  };

  const error = get(errors, fieldName) || get(errors, idFieldName);

  return (
    <div css={blockStyle}>
      <InputHidden name={idFieldName} />
      <InputHidden name={typeFieldName} />
      <InputHidden name="center_coordinates[]" />
      {isPhone(breakpoint) ? (
        <>
          <SuggestionFieldButton
            namespace={FormNamespaces.hotel}
            name={fieldName}
            label={{
              namespace: "hotel",
              label: "都市・地域名、ホテル名から検索",
              colorStyleKey: labelColorStyleKey,
            }}
            longName={value}
            placeholder={t("form.placeholders.hotel.destinationPlaceholder")}
            onClick={onPickerButtonClick}
            borderColorStyleKey={borderColorStyleKey}
            error={error}
          />
          <FullScreenModalDialog
            displayed={displayedPicker}
            closeHandler={hidePicker}
            colorMode="light"
            disableStopBodyScrolling={disableStopBodyScrolling}
          >
            <div css={headerTitleStyle}>{t("hotel.destination")}</div>
            <SuggestionPicker
              name={fieldName}
              suggestions={suggestions}
              placeholder={t("form.placeholders.hotel.destinationPlaceholder")}
              isLoading={isLoading}
              suggestOptions={suggestOptions}
              onRequest={fetchHotelDestinationSuggestions}
              onSelected={onSelected}
              onFocus={onFocus}
              onBlur={onBlur}
              onClear={onClearInput}
            />
          </FullScreenModalDialog>
        </>
      ) : (
        <InputAutosuggestBlock
          namespace="hotel"
          label={{
            namespace: "hotel",
            label: "都市・地域名、ホテル名から検索",
            colorStyleKey: labelColorStyleKey,
          }}
          input={{
            name: fieldName,
            value: value,
            type: "text",
            placeholder: "目的地またはホテル名を入力",
          }}
          error={error}
          onSelected={onSelected}
          onFocus={onFocus}
          onBlur={onBlur}
          fetchDestinationSuggest={fetchHotelDestinationSuggestions}
          suggestOptions={suggestOptions}
          suggestions={suggestions}
          onClear={onClearInput}
          disableOnFocusRequest
          alwaysShowSuggestions
          borderColorStyleKey={borderColorStyleKey}
        />
      )}
    </div>
  );
};

const blockStyle = css`
  padding: ${space.atom} 0 0;
  flex: 1;
`;
const headerTitleStyle = css`
  position: absolute;
  top: 0;
  left: 50%;
  line-height: 3rem;
  font-size: ${fontSize.mediumHeading};
  font-weight: bold;
  letter-spacing: ${letterSpacing.text};
  transform: translateX(-50%);
`;
